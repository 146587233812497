<template>
  <b-button class="button event-contact-information-button" type="is-outlined" @click="openLink">
    <div class="event-contact-information-inner-container">
      <div class="event-contact-information-icon-container">
        <slot name="icon"></slot>
      </div>
      <div class="event-contact-information-text-container">
        <slot></slot>
      </div>
    </div>
  </b-button>
</template>

<script>
export default {
  name: "EventContactInformationButton",
  props: {
    link: {
      type: String,
      required: true,
    },
  },

  methods: {
    openLink() {
      window.open(this.link, "_blank");
    }
  }
};
</script>

<style scoped></style>
